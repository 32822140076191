import react, { useReducer } from "react";
import { Text, View, Button } from "react-native";
import style from "./styleFolder/styles.js";

const reducer = (counter, action) => {
  switch (action.type) {
    case "increment_counter":
      return { ...counter, value: counter.value + action.payload };
    case "decrement_counter":
      return { ...counter, value: counter.value - action.payload };
    default:
      return counter;
  }
};

const CounterScreen = () => {
  const [counter, dispatch] = useReducer(reducer, { value: 0 });

  return (
    <View>
      <Button
        title="Increase"
        onPress={() => {
          dispatch({ type: "increment_counter", payload: 1 });
        }}
      />
      <Button
        title="Decrease"
        onPress={() => {
          dispatch({ type: "decrement_counter", payload: 1 });
        }}
      />
      <Text style={style.text}>Current Count: {counter.value}</Text>
    </View>
  );
};

export default CounterScreen;
