import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import BoxScreen from "./BoxScreen.js";
import SquareScreen from "./SquareScreen.js";
import style from "./styleFolder/styles.js";
import TextScreen from "./TextScreen.js";
let localNavigation;

const HomeScreen = ({ navigation }) => {
  localNavigation = navigation;

  return (
    <View style={style.view}>
      <Text style={style.text}>HomeScreen</Text>
      {ComponentButton()}
      {ListButton()}
      {ImageScreenButton()}
      {CounterScreenButton()}
      {ColorScreenButton()}
      {SquareScreenButton()}
      {TextScreenButton()}
      {BoxScreenButton()}
    </View>
  );
};

const ComponentButton = () => {
  return (
    <TouchableOpacity onPress={ComponentButtonOnPress}>
      <Text style={style.button}>Go to Component Demo</Text>
    </TouchableOpacity>
  );
};

const ListButton = () => {
  return (
    <TouchableOpacity onPress={ListButtonOnPress}>
      <Text style={style.button}>Go to List Demo</Text>
    </TouchableOpacity>
  );
};

const ImageScreenButton = () => {
  return (
    <TouchableOpacity onPress={ImageScreenOnPress}>
      <Text style={style.button}>Go to Image Screen</Text>
    </TouchableOpacity>
  );
};

const CounterScreenButton = () => {
  return (
    <TouchableOpacity onPress={CounterScreenOnPress}>
      <Text style={style.button}>Go to Counter Screen</Text>
    </TouchableOpacity>
  );
};

const ColorScreenButton = () => {
  return (
    <TouchableOpacity onPress={ColorScreenOnPress}>
      <Text style={style.button}>Go to Color Screen</Text>
    </TouchableOpacity>
  );
};

const SquareScreenButton = () => {
  return (
    <TouchableOpacity onPress={SquareScreenOnPress}>
      <Text style={style.button}>Go to Square Screen</Text>
    </TouchableOpacity>
  );
};

const TextScreenButton = () => {
  return (
    <TouchableOpacity onPress={TextScreenOnPress}>
      <Text style={style.button}>Go to Text Screen</Text>
    </TouchableOpacity>
  );
};

const BoxScreenButton = () => {
  return (
    <TouchableOpacity onPress={BoxScreenOnPress}>
      <Text style={style.button}>Go to Box Screen</Text>
    </TouchableOpacity>
  );
};

const ComponentButtonOnPress = () => {
  localNavigation.navigate("Components");
};

const ListButtonOnPress = () => {
  localNavigation.navigate("List");
};

const ImageScreenOnPress = () => {
  localNavigation.navigate("ImageScreen");
};
const CounterScreenOnPress = () => {
  localNavigation.navigate("CounterScreen");
};

const ColorScreenOnPress = () => {
  localNavigation.navigate("ColorScreen");
};

const SquareScreenOnPress = () => {
  localNavigation.navigate("SquareScreen");
};

const TextScreenOnPress = () => {
  localNavigation.navigate("TextScreen");
};

const BoxScreenOnPress = () => {
  localNavigation.navigate("BoxScreen");
};

export default HomeScreen;
