import react, { useState } from "react";
import { FlatList, View, Button } from "react-native";
import style from "./styleFolder/styles.js";
import randomRgb from "../helpClasses/ColorHelper.js";

const ColorScreen = () => {
  const [colors, setColors] = useState([]);

  return (
    <View>
      <Button
        title="Add a Color"
        onPress={() => {
          setColors([...colors, randomRgb()]);
        }}
      />
      <FlatList
        data={colors}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </View>
  );
};

const renderItem = ({ item }) => {
  return <View style={[style.colorScreenView, { backgroundColor: item }]} />;
};

const keyExtractor = (item) => item;

export default ColorScreen;
