import { StyleSheet } from "react-native";

const text = StyleSheet.create({
  button: {
    backgroundColor: "blue",
    fontSize: 30,
    textAlign: "center",
    width: "100%",
    padding: 10,
  },
  text: {
    fontSize: 40,
    textAlign: "center",
  },
  view: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imageScreen: {},
  outerView: {
    flex: 1,
    padding: 10,
  },
  imageDetail: { padding: 10 },
  colorScreenView: {
    width: 100,
    height: 100,
    backgroundColor: "red",
  },
  inputTextView: {
    margin: 15,
    borderColor: "orange",
    borderWidth: 1,
    fontSize: 20,
  },
});

export default text;
