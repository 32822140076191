import react from "react";
import { Image, Text, View } from "react-native";
import style from "../screens/styleFolder/styles.js";

const ImageDetail = ({ title, score, imageSource }) => {
  return (
    <View>
      <Text>{title}</Text>
      <Text>{test(score)}</Text>
      <Image source={imageSource} />
    </View>
  );
};

const test = (score) => {
  return "Image score:  " + score;
};
export default ImageDetail;
