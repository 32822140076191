import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  text: {
    fontSize: 40,
    textAlign: "center",
  },
  viewStyle: {
    borderWidth: 5,
    borderColor: "black",
    height: 200,
  },
  textStyle: {
    borderWidth: 3,
    borderColor: "red",
  },
  textOneStyle: {
    borderWidth: 3,
    borderColor: "red",
  },

  textTwoStyle: {
    borderWidth: 3,
    borderColor: "red",
  },
  textThreeStyle: {
    borderWidth: 3,
    borderColor: "red",
    ...StyleSheet.absoluteFillObject,
  },
});

export default style;
