import react from "react";
import { Text, View } from "react-native";
import ImageDetail from "../components/ImageDetail";
import style from "./styleFolder/styles.js";

const ImageScreen = () => {
  return (
    <View style={style.outerView}>
      <ImageDetail
        title={"Forest"}
        imageSource={require("../../assets/forest.jpg")}
        score={1}
      />
      <ImageDetail
        title={"Beach"}
        imageSource={require("../../assets/beach.jpg")}
        score={2}
      />
      <ImageDetail
        title={"Mountain"}
        imageSource={require("../../assets/mountain.jpg")}
        score={9}
      />
    </View>
  );
};

export default ImageScreen;
