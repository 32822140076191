import react, { useState } from "react";
import { View, Text, Button, TextInput } from "react-native";
import style from "./styleFolder/styles.js";

const TextScreen = () => {
  const [password, setPassword] = useState("");
  return (
    <View>
      <Text style={style.text}>Enter password</Text>
      <TextInput
        style={style.inputTextView}
        autoCapitalize="none"
        autoCorrect={false}
        onChangeText={(newValue) => {
          setPassword(newValue);
        }}
        value={password}
        secureTextEntry={true}
        placeholder="Enter password"
        onSubmitEditing={() => {
          console.log(password);
        }}
      />
      {password.length < 6 ? (
        <Text>Password must be longer than 5 characters</Text>
      ) : (
        <Text>Password OK</Text>
      )}
    </View>
  );
};

export default TextScreen;
