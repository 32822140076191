import react from "react";
import { View, Text, Button } from "react-native";
import style from "./styleFolder/BoxScreenStyle.js";

const BoxScreen = () => {
  return (
    <View style={style.viewStyle}>
      <Text style={style.textOneStyle}>Child #1</Text>
      <Text style={style.textTwoStyle}>Child #2</Text>
      <Text style={style.textThreeStyle}>Child #3</Text>
    </View>
  );
};

export default BoxScreen;
