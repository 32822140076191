import react, { useReducer } from "react";
import { View, Text, Button } from "react-native";
import ColorCounter from "../components/ColorCounter";

const COLOR_INCREMENT = 50;

const reducer = (state, action) => {
  // state === {red: number, green: number, blue: number}
  // action === {type: `change_red`, || `change_green` || `change_blue`, payload: 15 || -15}

  switch (action.type) {
    case `change_red`:
      return state.red + action.payload > 255 || state.red + action.payload < 0
        ? state
        : { ...state, red: state.red + action.payload };

    case `change_green`:
      return state.green + action.payload > 255 ||
        state.green + action.payload < 0
        ? state
        : { ...state, green: state.green + action.payload };

    case `change_blue`:
      return state.blue + action.payload > 255 ||
        state.blue + action.payload < 0
        ? state
        : { ...state, blue: state.blue + action.payload };
    case `reset`:
      return { red: 0, green: 0, blue: 0 };
    default:
      return state;
  }
};

const SquareScreen = () => {
  const [state, dispatch] = useReducer(reducer, {
    red: 0,
    green: 0,
    blue: 0,
  });
  const { red, green, blue } = state;

  const inCreaseRed = () => {
    dispatch({ type: `change_red`, payload: COLOR_INCREMENT });
  };
  const inCreaseBlue = () => {
    dispatch({ type: `change_blue`, payload: COLOR_INCREMENT });
  };
  const inCreaseGreen = () => {
    dispatch({ type: `change_green`, payload: COLOR_INCREMENT });
  };

  const decreaseRed = () => {
    dispatch({ type: `change_red`, payload: -1 * COLOR_INCREMENT });
  };
  const decreaseBlue = () => {
    dispatch({ type: `change_blue`, payload: -1 * COLOR_INCREMENT });
  };
  const decreaseGreen = () => {
    dispatch({ type: `change_green`, payload: -1 * COLOR_INCREMENT });
  };

  return (
    <View>
      <ColorCounter
        color="Red"
        onIncrease={inCreaseRed}
        onDecrease={decreaseRed}
      />
      <ColorCounter
        color="Blue"
        onIncrease={inCreaseBlue}
        onDecrease={decreaseBlue}
      />
      <ColorCounter
        color="Green"
        onIncrease={inCreaseGreen}
        onDecrease={decreaseGreen}
      />
      <View
        style={{
          height: 150,
          width: 150,
          backgroundColor: `rgb(${red},${green},${blue})`,
        }}
      ></View>
      <Button
        title="Reset"
        onPress={() => {
          dispatch({ type: `reset`, payload: null });
        }}
      />
    </View>
  );
};

export default SquareScreen;
